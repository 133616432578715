import { useLocation, useSearchParams } from 'react-router-dom';

import { areFiltersDefault } from 'modules/search/helpers/areFiltersDefault';
import { hasLocation } from 'modules/search/helpers/sidefilters';
import { SearchPageLocationState } from 'modules/search/pages/Search/types/SearchPageLocationState';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { useMainSelector } from 'store/hooks/useMainSelector';

type Args = {
  initialized: boolean;
  searchFacets: SearchFacet[];
};

export function useSearchFiltersSidebarState({
  initialized,
  searchFacets,
}: Args) {
  const location = useLocation<SearchPageLocationState>();

  const { query, sort, filters, searchLocation, jobFamilyFacetData } =
    useMainSelector((state) => state.mainSearch);

  const searchRadius = useMainSelector((state) => state.searchRadius);

  const searchLocationActive = hasLocation(searchLocation);

  const defaultFiltersSet =
    areFiltersDefault(searchFacets, filters) && !searchLocationActive;

  const [searchParams] = useSearchParams();
  const jobSynonymId = searchParams.get('synonymId');
  const jobFamilyFacetLoaded = jobSynonymId
    ? jobFamilyFacetData?.jobSynonym.id === jobSynonymId
    : true;

  return {
    sidebarReady: initialized && jobFamilyFacetLoaded,
    query,
    keywords: location.state?.remoteKeywords || [],
    sort,
    filters,
    jobFamilyFacetData,
    defaultFiltersSet,
    searchLocation,
    searchRadius: searchRadius.value,
  };
}
