import invariant from 'invariant';
import queryString from 'query-string';

import { searchConvertFiltersByNameToFiltersById } from 'modules/search/helpers/searchConvertFiltersByNameToFiltersById';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchType } from 'modules/search/types/SearchType';
import { MainStoreDispatch } from 'store/MainStoreDispatch';
import { MainStoreState } from 'store/MainStoreState';
import { setSearchRadius } from 'store/ducks/searchRadius.actions';
import {
  setPageIndex,
  setSearchFilters,
  setSearchQuery,
  setSearchSort,
} from 'store/mainSearch/mainSearch.actions';
import { getDefaultSearchRadius } from 'utils/constants/general/defaultSearchRadius';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';

type Args = {
  getState: () => MainStoreState;
  dispatch: MainStoreDispatch;
  searchType: SearchType;
  searchParams: URLSearchParams;
  searchFacets: SearchFacet[];
};

export function assignSearchPageUrlParametersToRedux({
  getState,
  dispatch,
  searchType,
  searchParams,
  searchFacets,
}: Args) {
  const { mainSearch, searchRadius } = getState();

  /*
   * Query
   */
  const urlQuery = searchParams.get('q') || '';
  const truncatedUrlQuery = truncateToUTF8Bytes(urlQuery, 512);

  if (truncatedUrlQuery !== mainSearch.query) {
    dispatch(setSearchQuery(truncatedUrlQuery));
  }

  /*
   * Filters
   */

  // Object.fromEntries(searchParams.entries()) does not work properly with array params
  const parsedParams = queryString.parse(searchParams.toString()) as Record<
    string,
    string | string[]
  >;

  const filtersById: SearchFiltersById = {
    type: searchType,
    ...searchConvertFiltersByNameToFiltersById(searchFacets, parsedParams),
  };

  if (
    searchType === 'VOLOP' &&
    (!filtersById.actionTypeFacet ||
      (Array.isArray(filtersById.actionTypeFacet) &&
        filtersById.actionTypeFacet.length === 0))
  ) {
    filtersById.actionTypeFacet = ['VOLOP'];
  }

  if (parsedParams.synonymId) {
    const jobFamilyId = mainSearch.jobFamilyFacetData?.familyId;
    invariant(jobFamilyId, 'Missing jobFamilyId when filters to redux');
    filtersById.jobFamilyFacet = [jobFamilyId];
  }

  dispatch(setSearchFilters(filtersById));

  /*
   * Sort
   */
  const urlSort =
    searchParams.get('sort') === 'newest' ? 'newest' : 'relevance';
  const reduxSort = mainSearch.sort || 'relevance';

  if (urlSort !== reduxSort) {
    dispatch(setSearchSort(urlSort));

    // Unless the user explicitly changed the search radius, update the search
    // radius when the sorting criteria changes
    if (!searchRadius.userHasChanged) {
      dispatch(setSearchRadius(getDefaultSearchRadius(urlSort), false));
    }
  }

  /*
   * Page Index
   */
  const urlPageIndex = parseInt(searchParams.get('page') || '1', 10) - 1;

  if (urlPageIndex !== mainSearch.pageIndex) {
    dispatch(setPageIndex(urlPageIndex));
  }

  /*
   * Radius
   */
  const urlRadius = searchParams.get('radius') || 'auto';
  const reduxRadius = searchRadius.value || 'auto';

  if (urlRadius !== reduxRadius) {
    dispatch(setSearchRadius(urlRadius, searchParams.has('radius')));
  }
}
