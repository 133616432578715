import type { SearchResponse } from '@algolia/client-search';
import { Fragment } from 'react';

import { CompensationSurveyAd } from 'components/Ads/CompensationSurveyAd';
import { Pagination } from 'components/Pagination';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { SearchHit } from 'modules/search/components/Hit/SearchHit';
import { SearchLoadingSectionMockResults } from 'modules/search/components/Loading/SearchLoadingSectionMockResults';
import { SaveSearchButton } from 'modules/search/components/SaveSearch/SaveSearchButton';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import { AdSection } from 'types/Ads/AdSection';
import { AdType } from 'types/Ads/AdType';
import { algoliaTrackClick } from 'utils/algolia/algoliaTrackClick';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { useAdType } from 'zustand-stores/adStore';

import {
  SearchResultsFooter,
  SearchResultsHitsHolder,
} from './SearchResultsHits.styled';
import { SearchResultsHitsEmpty } from './SearchResultsHitsEmpty';

type Props = {
  results: SearchResponse<SearchAlgoliaHit> | undefined;
  isSearching: boolean;
  searchType: SearchType;
  searchLocation: SearchLocation | undefined;
  clearAllFilters: () => void;
  getSearchTrackingData: () => Record<string, unknown>;
  saveSearch: () => void;
  saveableSearch: boolean;
  setPageIndex: (pageIndex: number, didUserChange: boolean) => void;
  searchFacets: SearchFacet[];
};

export function SearchResultsHits({
  results,
  isSearching,
  searchType,
  searchLocation,
  clearAllFilters,
  getSearchTrackingData,
  saveSearch,
  saveableSearch,
  setPageIndex,
  searchFacets,
}: Props) {
  const adType = useAdType(AdSection.INTERSTITIAL);

  if (!results || isSearching) return <SearchLoadingSectionMockResults />;

  const { hits, hitsPerPage, page, nbHits, nbPages, query, queryID } = results;

  if (results.hits.length === 0) {
    return (
      <SearchResultsHitsEmpty
        searchType={searchType}
        query={query}
        searchLocation={searchLocation}
        clearAllFilters={clearAllFilters}
      />
    );
  }

  return (
    <>
      <SearchResultsHitsHolder>
        {hits.map((hit, index) => (
          <Fragment key={hit.objectID}>
            <SearchHit
              hit={hit}
              variant="search"
              trackClick={() => {
                algoliaTrackClick(
                  queryID,
                  hit.objectID, // Position in algolia analytics is 1-indexed
                  hitsPerPage ? page * hitsPerPage + index + 1 : index + 1,
                );

                trackClicked('Search Result', {
                  org_id: 'orgID' in hit ? hit.orgID : undefined,
                  org_type: 'orgType' in hit ? hit.orgType : undefined,
                  result_id: hit.objectID,
                  result_index: index,
                  result_type: hit.type,
                  ...(hit.type === 'VOLOP'
                    ? {
                        action_type:
                          'actionType' in hit ? hit.actionType : undefined,
                        result_source:
                          'source' in hit ? hit.source : 'IDEALIST',
                      }
                    : {}),
                  ...getSearchTrackingData(),
                });
              }}
              searchContextFacets={searchFacets}
            />

            {((hits.length <= 15 && index === hits.length - 1) ||
              index === 4 ||
              (hits.length === 20 && index === 14)) &&
              adType === AdType.SALARY_SURVEY && <CompensationSurveyAd />}
          </Fragment>
        ))}
      </SearchResultsHitsHolder>

      <SearchResultsFooter>
        {saveableSearch && hits.length >= 5 && (
          <SaveSearchButton
            saveSearch={saveSearch}
            searchFacets={searchFacets}
          />
        )}

        <Pagination
          nbHits={nbHits}
          nbPages={nbPages}
          pageIndex={page}
          setPageIndex={setPageIndex}
          hitsPerPage={hitsPerPage}
        />
      </SearchResultsFooter>
    </>
  );
}
