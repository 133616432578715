import { useCallback } from 'react';
import { useStore } from 'react-redux';

import { useValueRef } from 'hooks/useValueRef';
import { searchGetTrackingDataFromState } from 'modules/search/helpers/analytics/searchGetTrackingDataFromState';
import { MainStoreState } from 'store/MainStoreState';

type Args = {
  searchFrom?: string;
};

export function useSearchAnalytics({ searchFrom }: Args) {
  // Don't trigger analytics change just because the searchFrom value changed
  const getState = useStore().getState as () => MainStoreState;
  const searchFromRef = useValueRef(searchFrom);

  const getSearchTrackingData = useCallback(() => {
    const state = getState();
    return searchGetTrackingDataFromState({
      mainSearch: state.mainSearch,
      radius: state.searchRadius.value,
      searchFrom: searchFromRef.current,
    });
  }, [getState, searchFromRef]);

  return { getSearchTrackingData };
}
