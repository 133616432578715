import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box';
import { AnchorButton } from 'components/Button/AnchorButton';
import { LegacyTertiaryButton } from 'components/Button/LegacyTertiaryButton';
import { Hide } from 'components/Hide';
import { Body } from 'components/Text/Body';
import { H2 } from 'components/Text/H2';
import { H4 } from 'components/Text/H4';
import { Tile } from 'components/Tile';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import { OBJECT_TYPE_PLURAL_HUMAN_NAME_MAP } from 'utils/constants/general/objectTypePluralHumanNameMap';
import { getStaticImageSrc } from 'utils/getImageSrc';
import { scrollToTop } from 'utils/scroll';

import { getSearchResultsEmptyMessage } from './getSearchResultsEmptyMessage';

type Props = {
  searchType: SearchType | 'ARTICLE';
  query: string;
  searchLocation: SearchLocation | undefined;
  clearAllFilters: () => void;
};

export function SearchResultsHitsEmpty({
  searchType,
  query,
  searchLocation,
  clearAllFilters,
}: Props) {
  const resultType = OBJECT_TYPE_PLURAL_HUMAN_NAME_MAP[searchType];

  const clearAll = () => {
    scrollToTop();
    clearAllFilters();
  };

  const message = getSearchResultsEmptyMessage(
    resultType,
    query,
    searchLocation,
  );

  return (
    <div>
      <Box
        display={['block', null, null, 'flex']}
        justifyContent="space-between"
        mb={4}
      >
        <div style={{ flex: '1 1 auto', paddingRight: 16 }}>
          <H4 mb={0}>
            {sprintf(getText('No %(resultType)s match your search'), {
              resultType,
            })}
            {message && ': '}
            {message}
          </H4>
        </div>

        <Box flex="0 0 auto" lineHeight={1.75}>
          <LegacyTertiaryButton onClick={clearAll}>
            {getText('Clear all filters')}
          </LegacyTertiaryButton>
        </Box>
      </Box>

      <Tile
        gradientBorder
        position="relative"
        display="flex"
        flex="1"
        py={5}
        px={4}
        borderRadius="4px"
      >
        <Box display="flex" justifyContent="center" width={[0, 0, '50%']}>
          <Hide xs sm>
            <img
              src={getStaticImageSrc('broken-computer.svg')}
              alt="Illustration"
            />
          </Hide>
        </Box>
        <Box
          textAlign={['center', 'center', 'left']}
          width={['100%', '100%', '50%']}
        >
          <H2 mb={2}>{getText('Looking for additional help?')}</H2>
          <Body mb={4}>{getText('Visit our Help Center for more info.')}</Body>
          <AnchorButton
            href={getText(
              /* TRANSLATORS: search: help center link url */
              '/en/help',
            )}
          >
            {
              /* TRANSLATORS: search: help center link text */
              getText('Help Center')
            }
          </AnchorButton>
        </Box>
      </Tile>
    </div>
  );
}
