import styled from 'styled-components';

import { LegacyButton as Button } from 'components/Button/LegacyButton';
import { Link } from 'components/Link';
import { colors, fontWeights, transition } from 'theme/theme';

export const PageLink = styled(Link)`
  display: flex;
  padding: 4px;
  margin: 0 10px;
  color: ${colors.mediumContentGrey};
  font-weight: ${fontWeights.semiBold};

  &,
  &:active,
  &:visited,
  &:hover {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: ${colors.hoverBlue};
  }
`;

export const CurrentPage = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  background-color: ${colors.brandBlue};
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  color: white;
  font-weight: ${fontWeights.semiBold};
`;

export const ArrowLink = styled(Link)<{
  $disabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &,
  svg {
    transition: all ${transition};
  }

  svg {
    * {
      fill: ${colors.brandBlue};
    }
  }

  ${(props) =>
    props.$disabled &&
    `
    pointer-events: none;
    svg {
      * {
        fill: ${colors.elementGrey};
      }
    }
  `}
  &:hover, &:focus {
    background: ${colors.calloutGrey};

    svg {
      * {
        fill: ${colors.hoverBlue};
      }
    }
  }
`;
