import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box';
import { SecondaryButtonRouterLink } from 'components/Button';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { BodyMicro } from 'components/Text/BodyMicro';
import { H5 } from 'components/Text/H5';
import { Text } from 'components/Text/Text';
import { colors, gutterWidth, transition } from 'theme/theme';
import { LocalProductNotificationType } from 'types/LocalNotification/LocalProductNotificationType';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEventOnce } from 'utils/analytics/track/trackEventOnce';
import { getStaticImageSrc } from 'utils/getImageSrc';
import { clearAdStore } from 'zustand-stores/adStore';
import { dismissLocalNotification } from 'zustand-stores/localNotificationsStore';

const CloseButton = styled(Button)`
  display: block;
  opacity: 0.7;
  transition: all ${transition};

  &:hover {
    opacity: 1;
  }

  svg path {
    stroke-width: 1px;
    stroke-color: white;
  }
`;

export function SalarySurveySidebarAd() {
  const dismiss = useCallback(() => {
    dismissLocalNotification(LocalProductNotificationType.SALARY_SURVEY_CTA);
    clearAdStore();
  }, []);

  useEffect(() => {
    trackEventOnce('Displayed: Salary Survey Ad', {
      context: 'Search Promo',
    });
  }, []);

  return (
    <Box
      data-qa-id="sidebar-ad"
      data-ad-type="salary-survey"
      px={[gutterWidth / 2, null, null, 0]}
    >
      <Box
        position="relative"
        overflow="hidden"
        bg={colors.brandBlue}
        borderRadius="8px"
        color="white"
        mb={32}
      >
        <Box
          position="absolute"
          left={0}
          top={0}
          width={1}
          height="100%"
          backgroundImage={`url('${getStaticImageSrc(
            'salary-survey-ad.svg',
          )}')`}
          backgroundSize="cover"
          backgroundPosition="center"
        />
        <Box position="relative" width={1} px={32} pt={40} pb={20}>
          <H5 mb={10} lineHeight={1.375}>
            {getText('Are you being paid fairly?')}
          </H5>
          <BodyMicro lineHeight={1.375} marginBottom={20}>
            {getText(
              'Find out how much you could be earning with Nonprofit Salary Explorer',
            )}
          </BodyMicro>

          <Box flex="1 0 auto">
            <SecondaryButtonRouterLink
              to="/nonprofit-salary-explorer"
              fullWidth
              reverse
              onClick={() => {
                trackClicked('Salary Survey Ad', {
                  cta_type: 'Button',
                  button_text: getText('Take Our Survey'),
                  url: '/nonprofit-salary-explorer',
                  context: 'Search Promo',
                });
              }}
            >
              <Box
                height={24}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text styleAs="h5" my={0}>
                  {getText('Take Our Survey')}
                </Text>
              </Box>
            </SecondaryButtonRouterLink>
          </Box>
        </Box>

        <Box position="absolute" top={10} right={10}>
          <CloseButton
            data-qa-id="close-sidebar-ad-button"
            onClick={dismiss}
            tabIndex={-1}
          >
            <Icon name="close" size={16} />
          </CloseButton>
        </Box>
      </Box>
    </Box>
  );
}
