import { ReactNode, useCallback } from 'react';

import { updateApplicantProfile } from 'store/ducks/userEnvironment.actions';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { LocalProductNotificationType } from 'types/LocalNotification/LocalProductNotificationType';
import { clearAdStore } from 'zustand-stores/adStore';
import { dismissLocalNotification } from 'zustand-stores/localNotificationsStore';

function ResumeDatabaseResponseContainer({
  children,
}: {
  children: (arg0: { dismiss: () => void }) => ReactNode;
}) {
  const { user } = useUserEnvironment();
  const dispatch = useMainDispatch();

  const dismiss = useCallback(() => {
    if (user) {
      dispatch(
        updateApplicantProfile({
          isRecruitable: false,
        }),
      );
    }

    // TODO why not dismiss when the user is not logged in?
    dismissLocalNotification(LocalProductNotificationType.UPLOAD_RESUME);
    clearAdStore();
  }, [dispatch, user]);

  return <>{children({ dismiss })}</>;
}

// eslint-disable-next-line import/no-default-export
export default ResumeDatabaseResponseContainer;
